/**
 * Created by slava on 17/9/19
 */

const QuestWindow = CleverappsWindow.extend({ 
    onWindowLoaded: function () {
        this.building = cleverapps.meta.getSelectedLocation().getBuilding();
        this.quest = this.building.getQuest();

        const content = this.createContent();

        this._super({
            title: `building.${this.building.name}`,
            name: "questwindow",
            content,
            help: this.showHelp.bind(this),
            styles: cleverapps.styles.QuestWindow
        });

        if (cleverapps.config.debugMode && !cleverapps.config.wysiwygMode) {
            this.createDebugInfo();
        }
    },

    createDebugInfo: function () {
        const farm = cleverapps.meta.getSelectedLocation();

        const savedBuildingsLevels = [];
        let savedKeys;
        let seed;

        const saveCurrent = function () {
            farm.buildings.forEach((building) => {
                savedBuildingsLevels.push(building.level);
            });
            savedKeys = cleverapps.clone(farm.keys, true);
            seed = cleverapps.Random.state;
        };

        const restoreSave = function () {
            farm.keys = savedKeys;
            farm.buildings.forEach((building, index) => {
                building.level = savedBuildingsLevels[index];
            });
            cleverapps.Random.state = seed;
        };

        const takeKeys = function (keys) {
            keys.forEach((key) => {
                if (farm.keys[key.name] === undefined || farm.keys[key.name] < key.amount) {
                    throw new Error("Cannot take keys");
                }
                farm.keys[key.name] -= key.amount;
            }, this);
        }.bind(this);

        const calcTillNextQuest = function (quest) {
            let levels = 0;
            while (!quest.isCompleted()) {
                const building = farm.findProperBuilding(quest.findActiveItem());
                building.level++;
                farm.keys[building.provides]++;
                levels++;
            }
            return levels;
        };

        saveCurrent();

        const tillQuests = [];

        let questId = this.building.currentQuestId;
        while (this.building.quests[questId]) {
            tillQuests.push(calcTillNextQuest(this.building.quests[questId]));
            takeKeys(this.building.quests[questId].items);
            questId++;
        }

        const info = [];

        info.push(cleverapps.UI.generateTTFText(
            `Quests: ${this.building.currentQuestId + 1}/${this.building.quests.length}`,
            cleverapps.styles.FONTS.SMALL_TEXT
        ));
        info.push(cleverapps.UI.generateTTFText(`Till next quest: ${tillQuests[0]}`, cleverapps.styles.FONTS.SMALL_TEXT));
        info.push(cleverapps.UI.generateTTFText(`Till next building: ${tillQuests.reduce((acc, val) => acc + val)}`, cleverapps.styles.FONTS.SMALL_TEXT));
        info.push(cleverapps.UI.generateTTFText(`Building ID: ${this.building.id}`, cleverapps.styles.FONTS.SMALL_TEXT));

        const layout = new cleverapps.Layout(info, {
            direction: cleverapps.UI.VERTICAL,
            margin: 0
        });

        this.window.addChild(layout);
        layout.setPositionRound({ x: { align: "center" }, y: { align: "bottom", dy: -90 } });

        restoreSave();
    },

    createButton: function () {
        if (this.quest.isCompleted()) {
            return new cleverapps.UI.Button({
                text: Messages.get("Complete").toUpperCase(),
                width: cleverapps.styles.QuestWindow.button.width,
                onClicked: function () {
                    this.close();

                    cleverapps.focusManager.distract({
                        focus: `NextQuest.${this.building.id}.${this.building.getStage()}`,
                        action: function (f) {
                            cleverapps.meta.getSelectedLocation().moveNext(f);
                        }
                    });
                }.bind(this)
            });
        }
    },

    showHelp: function () {
        cleverapps.focusManager.distract({
            focus: `questHelp.${Date.now()}`,
            action: function (f) {
                cleverapps.meta.getSelectedLocation().trigger("change:stage", f);
            }
        });
        this.close();
    },

    createContent: function () {
        const styles = cleverapps.styles.QuestWindow;

        const items = [this.createSubTitle(), this.createCharNode(),
            this.createItemsNode(), this.createProgressBar(), this.createButton()];

        return new cleverapps.Layout(items, {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.margin
        });
    },

    createSubTitle: function () {
        return cleverapps.UI.generateOnlyText(Messages.get(this.quest.title), cleverapps.styles.FONTS.WINDOW_TEXT);
    },

    createProgressBar: function () {
        let amount = 0;
        let status = 0;
        this.quest.getStatus().forEach((item) => {
            amount += item.amount;
            status += Math.min(item.amount, item.status);
        });

        const styles = cleverapps.styles.QuestWindow.progressBar;

        const progressBar = this.bar = new ScaledProgressBar({
            background: bundles.progress_bar.frames.bg_farmwindow,
            progress: bundles.progress_bar.frames.bar_farmwindow,
            barText: {
                font: cleverapps.styles.FONTS.QUEST_WINDOW_PROGRESS_TEXT,
                dy: styles.text.dy
            }
        });
        progressBar.setGoal(amount);
        progressBar.setPercentage(status);
        progressBar.setLength(styles.width);

        const prize = new cc.Sprite(bundles.main.frames.thg_present_box_pink);
        prize.setScale(styles.prize.scale);
        progressBar.addChild(prize);
        prize.setAnchorPoint(0.5, 0.5);
        prize.setPositionRound(styles.prize);

        cleverapps.tooltipManager.create(prize, {
            rewards: cleverapps.meta.getSelectedLocation().getQuestPrize(true),
            text: Messages.get("StarsProgressBar.prizeTooltip")
        });

        return progressBar;
    },

    createCharNode: function () {
        const styles = cleverapps.styles.QuestWindow.charNode;
        const textBg = cleverapps.UI.createScale9Sprite(bundles.farm_windows.frames.speech_bubble_bg_png, cleverapps.UI.Scale9Rect.TwoPixelXY);
        textBg.setContentSize(styles.textNode);

        const summary = this.getSummary();
        summary.setPosition(textBg.width / 2 + styles.textNode.text.offsetX, textBg.height / 2);
        textBg.addChild(summary);

        const node = new cleverapps.Layout([this.getCharAnimation(), textBg], {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.margin
        });

        cleverapps.UI.onClick(node, this.showHelp.bind(this));

        return node;
    },

    getSummary: function () {
        const styles = cleverapps.styles.QuestWindow.charNode;
        const summary = cleverapps.UI.generateTTFText(Messages.get(this.quest.summary), cleverapps.styles.FONTS.LEVEL_TUTORIAL_TEXT);
        summary.setHorizontalAlignment(cc.TEXT_ALIGNMENT_CENTER);
        summary.setDimensions(styles.textNode.text.width, 0);
        summary.fitTo(undefined, styles.textNode.text.height);
        return summary;
    },

    getCharAnimation: function () {
        const char = new cc.Node();
        const styles = cleverapps.styles.QuestWindow.charNode;
        char.setAnchorPoint(0.5, 0.5);
        char.setContentSize2(styles);

        const charAnimation = new Person(this.quest.person);
        charAnimation.setPositionRound(styles.position.x, styles.position.y);

        char.addChild(charAnimation);
        return char;
    },

    createItemsNode: function () {
        const styles = cleverapps.styles.QuestWindow.itemsNode;

        let finger = undefined;
        const itemViews = this.quest.getStatus().map(function (item) {
            const view = new QuestItemView(item);
            if (item.status < item.amount) {
                cleverapps.UI.applyHover(view);
                cleverapps.UI.onClick(view, () => {
                    this.close();

                    const building = cleverapps.meta.getSelectedLocation().findProperBuilding(item);
                    cleverapps.focusManager.distract({
                        focus: "scrollToItem",
                        actions: [
                            function (f) {
                                cleverapps.meta.getSelectedLocation().scrollTo(building, f);
                            },

                            function (f) {
                                setTimeout(f, 500);
                            },

                            function (f) {
                                cleverapps.meta.playLevel(f, cleverapps.meta.getCurrentLevel(building));
                            }
                        ]
                    });
                });
                if (!finger) {
                    finger = FingerView.hintTap(view);
                }
            }
            return view;
        }, this);

        const items = new cleverapps.Layout(itemViews, {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.itemsMargin
        });

        const nodeTitle = cleverapps.UI.generateTTFText("QuestWindow.text2", cleverapps.styles.FONTS.WINDOW_TEXT);
        const itemsNode = new cleverapps.Layout([nodeTitle, items], {
            direction: cleverapps.UI.VERTICAL,
            margin: styles.margin,
            padding: styles.padding
        });

        if (bundles.farm_windows.frames.itemsBg) {
            const itemsNodeBg = cleverapps.UI.createScale9Sprite(bundles.farm_windows.frames.itemsBg, cleverapps.UI.Scale9Rect.TwoPixelXY);
            itemsNodeBg.setContentSize2(cleverapps.styles.QuestWindow.itemsNode.bgWidth, itemsNode.height);
            itemsNodeBg.setPosition(itemsNode.width / 2, itemsNode.height / 2);
            itemsNode.addChild(itemsNodeBg, -1);
        }

        return itemsNode;
    },

    listBundles: function () {
        const bundles = ["farm_windows"];
        bundles.push(cleverapps.meta.getSelectedLocation().getBuilding().getQuest().person);
        return bundles;
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    QUEST_WINDOW_PROGRESS_TEXT: {
        size: 33,
        color: cleverapps.styles.COLORS.WHITE
    }
});

cleverapps.styles.QuestWindow = {
    width: 650,
    margin: 40,

    progressBar: {
        width: 522,

        prize: {
            scale: 0.6,
            margin: 5,
            x: { align: "right", anchor: false, dx: 10 },
            y: { align: "center" }
        },

        text: {
            dy: 2
        }
    },

    button: {
        width: 300
    },

    itemsNode: {
        itemsMargin: 10,
        margin: 25,
        padding: { x: 40, y: 20 },
        bgWidth: 680
    },

    charNode: {
        margin: 0,
        width: 320,
        height: 250,
        position: {
            x: { align: "center" },
            y: { align: "center", dy: -430 }
        },
        textNode: {
            width: 350,
            height: 270,
            text: {
                offsetX: 15,
                width: 285,
                height: 255
            }
        }
    }
};
