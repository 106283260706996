/**
 * Created by r4zi4l on 05.04.2024
 */

RewardTypes.keys = {
    parse: function (value, options) {
        return Object.keys(value)
            .filter((name) => value[name])
            .map((name) => new Reward("keys", { name, amount: value[name] }, options));
    },

    handler: function () {
        return function () {};
    }
};
