/**
 * Created by andrey on 19.11.19.
 */

const FarmScene = MainScene.extend({
    onSceneLoaded: function () {
        this._super();

        this.map = new WorldMap();
        this.screen.addChild(this.map);

        this.addPlayButton();

        this.controller = new FarmController(this.map);

        this.runAction(new cc.RepeatForever(new cc.Sequence(
            new cc.DelayTime(5),
            new cc.PlaySound(bundles.main.urls.city_loop_effect),
            new cc.DelayTime(5)
        )));

        this.controller.onEnter();
    },

    onExit: function () {
        this._super();

        if (this.controller) {
            this.controller.clear();
        }
    },

    addPlayButton: function () {
        const playButton = new PlayButtonView({
            isSmall: false,
            hard: false
        });
        this.addChild(playButton);

        cleverapps.focusManager.registerControl("play_button", new HidingNode(playButton, HidingNode.DIR.NONE));
    },

    playIntro: function (f, silent) {
        this.controller.showUp(f, silent);
    },

    getBackgroundStyles: function () {
        return undefined;
    },

    listBundles: function () {
        const res = ["world_map", "dialogues", "main", "farm_windows"];

        for (let buildingsId = 0; bundles[`metha_top_layer_${buildingsId}`]; buildingsId++) {
            res.push(`metha_top_layer_${buildingsId}`);
        }
        for (let roomsId = 0; bundles[`metha_bottom_layer_${roomsId}`]; roomsId++) {
            res.push(`metha_bottom_layer_${roomsId}`);
        }
        return res;
    }
});