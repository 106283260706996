/**
 * Created by slava on 2/9/19
 */

const Quest = function (farm, data) { 
    this.farm = farm;

    this.dialogue = data.dialogue;
    this.solved = false;

    this.person = data.person;
    this.title = data.dialogue;
    this.summary = data.summary;
    this.itemsDialogue = data.itemsDialogue;

    this.items = data.items; 
};

Quest.prototype.findActiveItem = function () {
    const keys = this.farm.keys;

    for (let i = 0; i < this.items.length; i++) {
        const item = this.items[i];

        if (keys[item.name] === undefined || keys[item.name] < item.amount) {
            return item;
        }
    }

    return undefined;
};

Quest.prototype.getStatus = function () {
    const keys = this.farm.keys;

    for (let i = 0; i < this.items.length; i++) {
        const item = this.items[i];

        if (keys[item.name] !== undefined) {
            item.status = keys[item.name];
        } else {
            item.status = 0;
        }
    }

    return this.items;
};

Quest.prototype.isCompleted = function () {
    return this.findActiveItem() === undefined;
};