/**
 * Created by mac on 2/5/20
 */

const FarmController = function (map) { 
    this.farm = cleverapps.meta.getSelectedLocation();
    this.map = map;

    this.scrollToHandler = this.farm.on("scrollTo", map.scrollTo.bind(map));
    this.changeStageHandler = this.farm.on("change:stage", this.changeQuest.bind(this));
    this.changeBuildingHandler = this.farm.on("change:building", this.changeBuilding.bind(this));
    this.changeItemCollectedHandler = this.farm.on("change:itemCollected", this.itemCollectedDialogue.bind(this));

    map.showBuildings(this.farm.buildings);
};

FarmController.prototype.clear = function () {
    this.scrollToHandler.clear();
    this.changeStageHandler.clear();
    this.changeBuildingHandler.clear();
    this.changeItemCollectedHandler.clear();

    this.farm = undefined;
    this.map = undefined;
};

FarmController.prototype.changeQuest = function (cb, finishedQuest) {
    this.map.removeTarget();

    cleverapps.focusManager.compound(cb, [
        function (f) {
            this.showQuestDialogue(f);
        }.bind(this),

        function (f) {
            if (finishedQuest) { 
                new RewardWindow(cleverapps.meta.getSelectedLocation().getQuestPrize());
                cleverapps.focusManager.onceNoWindowsListener = f;
            } else {
                f();
            }
        },

        function (f) {
            const building = this.farm.findBuildingForActiveItem();
            if (building) {
                this.map.addTarget(building);
            }

            new QuestWindow();
            cleverapps.focusManager.onceNoWindowsListener = f;
        }.bind(this)
    ]);
};

FarmController.prototype.changeBuilding = function (options, cb) {
    const finished = options.finished;
    const newbie = options.newbie;

    this.map.removeTarget();
    this.map.unhighlight(finished);

    cleverapps.focusManager.compound(cb, [
        function (f) {
            finished.trigger("openBuilding", f);
        },

        function (f) {
            if (this.farm.isCompleted()) {
                cleverapps.focusManager.compound(f, [
                    function (f) {
                        const dialogue = this.farm.createDialogue("Final");
                        dialogue.on("afterClose", f);

                        new DialogueView(dialogue);
                    }.bind(this),

                    function (f) {
                        new AllDoneWindow();
                        cleverapps.focusManager.onceNoWindowsListener = f;
                    }
                ]);
            } else {
                cleverapps.focusManager.compound(f, [
                    function (f1) {
                        this.showQuestDialogue(f1);
                    }.bind(this),

                    function (f1) {
                        new RewardWindow(cleverapps.meta.getSelectedLocation().getQuestPrize());
                        cleverapps.focusManager.onceNoWindowsListener = f1;
                    },

                    function (f1) {
                        this.map.scrollTo(newbie, false, f1);
                    }.bind(this),

                    function (f1) {
                        this.map.highlight(newbie);

                        const building = this.farm.findBuildingForActiveItem();
                        if (building) {
                            this.map.addTarget(building);
                        }

                        setTimeout(f1, 1000);
                    }.bind(this),

                    function (f1) {
                        new QuestWindow();
                        cleverapps.focusManager.onceNoWindowsListener = f1;
                    }
                ]);
            }
        }.bind(this)
    ]);
};

FarmController.prototype.itemCollectedDialogue = function (f) {
    const dialogue = this.farm.createDialogue(this.farm.justCollectedDialogue);
    delete this.farm.justCollectedDialogue;
    this.farm.save();

    dialogue.on("afterClose", f);
    new DialogueView(dialogue);
};

FarmController.prototype.onEnter = function () {
    this.farm.onEnter();

    const latestBuilding = this.farm.getLatestBuilding();
    this.map.scrollTo(latestBuilding, true);

    if (this.farm.getBuilding()) {
        this.map.highlight(this.farm.getBuilding());
        if (this.farm.currentBuildingId !== 0) {
            const targetBuilding = this.farm.findBuildingForActiveItem();
            if (targetBuilding) {
                this.map.addTarget(targetBuilding);
            }
        }
    }
};

FarmController.prototype.showQuestWindow = function (cb, silent) {
    if (this.farm.isCompleted()) {
        cb();
        return;
    }
    if (this.farm.currentBuildingId === 0) {
        const curQuest = this.farm.getBuilding().getQuest();

        if (curQuest.isCompleted()) {
            this.farm.moveNext(cb);
        } else if (FarmController.firstBuildingDialogueShown) {
            if (silent) {
                cb();
                return;
            }

            new QuestWindow();
            cleverapps.focusManager.onceNoWindowsListener = cb;
        } else {
            FarmController.firstBuildingDialogueShown = true;

            cleverapps.focusManager.compound(cb, [
                function (f) {
                    if (silent) {
                        f();
                        return;
                    }

                    this.showQuestDialogue(f);
                }.bind(this),

                function (f) {
                    cleverapps.meta.playLevel(f, cleverapps.meta.getCurrentLevel(this.farm.getBuilding()));
                }.bind(this)
            ]);
        }
    } else {
        cleverapps.focusManager.compound(cb, [
            function (f) {
                if (this.farm.justCollectedDialogue) {
                    if (silent) {
                        delete this.farm.justCollectedDialogue;
                        this.farm.save();
                        f();
                        return;
                    }

                    this.itemCollectedDialogue(f);
                } else {
                    f();
                }
            }.bind(this),

            function (f) {
                if (silent) {
                    f();
                    return;
                }

                new QuestWindow();
                cleverapps.focusManager.onceNoWindowsListener = f;
            }
        ]);
    }
};

FarmController.prototype.showUp = function (cb, silent) {
    if (!silent) {
        cleverapps.focusManager.compound(cb, [
            function (f) {
                let centerPoint;

                this.map.setZoom(cleverapps.styles.WorldMap.zoom.unfocused);

                if (this.farm.getBuilding()) {
                    centerPoint = {
                        x: this.farm.getBuilding().x * resolutionScale,
                        y: this.farm.getBuilding().y * resolutionScale
                    };
                }

                this.map.zoomTo({
                    zoom: cleverapps.styles.WorldMap.zoom.base,
                    timeout: 1000,
                    centerPoint,
                    onComplete: f
                });
            }.bind(this),

            function (f) {
                this.map.runAction(new cc.Sequence(
                    new cc.DelayTime(0.5),
                    new cc.CallFunc(f)
                ));
            }.bind(this),

            function (f) {
                this.showQuestWindow(f);
            }.bind(this)
        ]);
        return;
    }

    this.showQuestWindow(cb, silent);
};

FarmController.prototype.showQuestDialogue = function (f) {
    const quest = this.farm.getBuilding().getQuest();
    const dialogue = this.farm.createDialogue(quest.dialogue);

    dialogue.on("afterClose", f);

    new DialogueView(dialogue);
};