/**
 * Created by mac on 2019-09-06
 */

const FarmLocation = function (locationId) { 
    this.config = cc.loader.getRes(bundles.main.jsons.farm_data_json);

    this.probabilities = cleverapps.clone(this.config.probabilities);
    for (let i = 1; i < this.probabilities.length; i++) {
        this.probabilities[i] += this.probabilities[i - 1];
    }

    this.buildings = [];
    this.buildings = this.config.items.map(function (buildingData, index) {
        return new Building(this, buildingData, index);
    }, this);

    this.keys = {};

    BaseLocation.call(this, "farm", locationId);

    cleverapps.playSession.set(cleverapps.EVENTS.META_LOCATION, this.currentBuildingId);

    this.addPersons();

    if (cleverapps.config.debugMode) {
        this.validateDialogues();
    }
    this.validateQuestItems();
};

FarmLocation.prototype = Object.create(BaseLocation.prototype);
FarmLocation.prototype.constructor = FarmLocation;

FarmLocation.prototype.wantsToBeShown = function () {
    return !this.isCompleted() && (this.getBuilding().getQuest().isCompleted() || this.justCollectedDialogue);
};

FarmLocation.prototype.createDialogue = function (dialogueName) {
    const dialogue = this.config.dialogues[dialogueName];

    if (!dialogue) {
        return undefined;
    }

    return new Dialogue(dialogue, {
        autoClose: true,
        showUp: true,
        bundles
    });
};

FarmLocation.prototype.addPersons = function () {
    for (let i = 0; i < 100; i++) {
        const role = `person${i}`;
        if (!bundles[role]) {
            return;
        }

        cleverapps.persons.add(role, {
            name: role,
            bundle: role,
            json: bundles[role].jsons[role]
        });
    }
};

FarmLocation.prototype.validateDialogues = function () {
    Object.values(this.config.dialogues).forEach((dialogue) => {
        dialogue.forEach((step) => {
            const person = step.person === "string" && step.person.split("_")[0];
            if (person && !cleverapps.persons.choose(person)) {
                throw `No person: ${person}`;
            }
        });
    });
};

FarmLocation.prototype.validateQuestItems = function () {
    this.config.questItems.forEach((item, ind) => {
        if (!bundles.farm_items.frames[`item${ind}`]) {
            throw `No questItem resource - ${item}`;
        }
    });
};

FarmLocation.prototype.gamePlayed = function (game) {
    if (game.level.building) {
        game.rewards.keys = {};
        game.rewards.keys[game.level.building.levelUp()] = 1;
    }

    cleverapps.user.incLevel();
};

FarmLocation.prototype.chooseNextLevel = function () {
    let current = cleverapps.user.getCurrentLevel();

    if (this.existLevel(current) && !cleverapps.user.isPassedAll() && !this.isCompleted()) {
        cleverapps.user.incLevel();
        current = cleverapps.user.getCurrentLevel();
    }

    return current;
};

FarmLocation.prototype.getWantsToPlayFarmLevel = function (level) {
    const building = level && level.building && level.building.canStillProvideQuestItems()
        ? level.building
        : this.findBuildingForActiveItem() || cleverapps.Random.mathChoose(this.listAvailableProvidesBuildings());

    return cleverapps.meta.getCurrentLevel(building);
};

FarmLocation.prototype.getCurrentFarmLevel = function (building) {
    let label = building.label;

    cleverapps.playButton.disableIncLevel();
    if (label === undefined || label.episodeNo >= levels.user.getEpisodesAmount()) {
        const nextLevel = this.chooseNextLevel();
        label = {
            episodeNo: nextLevel.episodeNo,
            levelNo: nextLevel.levelNo
        };
    }

    if (building.label !== label) {
        building.label = label;
        this.save();
    }
    cleverapps.playButton.enableIncLevel();

    const level = new Level(label.episodeNo, label.levelNo);
    level.building = building;
    return level;
};

FarmLocation.prototype.existLevel = function (current) {
    for (let i = 0; i < this.buildings.length; i++) {
        const building = this.buildings[i];
        if (building.label && building.label.episodeNo === current.episodeNo && building.label.levelNo === current.levelNo) {
            return true;
        }
    }

    return false;
};

FarmLocation.prototype.getMapSize = function () {
    const data = this.config;
    return {
        width: data.map.width * resolutionScale,
        height: data.map.height * resolutionScale
    };
};

FarmLocation.prototype.findProperBuilding = function (item) {
    for (let i = 0; i < this.buildings.length; i++) {
        const building = this.buildings[i];

        if (building.provides === item.name) {
            return building;
        }
    }

    return undefined;
};

FarmLocation.prototype.findBuildingForActiveItem = function () {
    if (this.isCompleted()) {
        return undefined;
    }

    if (this.getBuilding().getQuest().isCompleted()) {
        return undefined;
    }

    return this.findProperBuilding(this.getBuilding().getQuest().findActiveItem());
};

FarmLocation.prototype.scrollTo = function (building, f) {
    this.trigger("scrollTo", building, false, f);
};

FarmLocation.prototype.complete = function (f) {
    new AllDoneWindow();
    AllDoneWindow.showed = true;
    cleverapps.focusManager.onceNoWindowsListener = f;
};

FarmLocation.prototype.load = function (data) {
    if (data && data.keys && data.buildings && data.building !== undefined) {
        this.keys = this.parseOldKeys(data.keys);

        for (let i = 0; i < data.buildings.length; i++) {
            if (this.buildings[i]) {
                this.buildings[i].load(data.buildings[i]);
            }
        }

        this.currentBuildingId = data.building;
        const building = this.getBuilding();
        if (building && data.quest !== undefined) {
            building.currentQuestId = data.quest;
        }

        if (data.justCollectedDialogue) {
            this.justCollectedDialogue = data.justCollectedDialogue;
        }
    } else {
        this.currentBuildingId = 0;
    }
};

FarmLocation.prototype.parseOldKeys = function (oldKeys) {
    if (oldKeys.mentos === undefined) {
        return oldKeys;
    }

    const newKeys = {};
    const oldPerLevelAmount = 3;
    for (const key in oldKeys) {
        if (this.findProperBuilding({ name: key })) {
            newKeys[key] = Math.round(oldKeys[key] / oldPerLevelAmount);
        }
    }

    return newKeys;
};

FarmLocation.prototype.getBuilding = function () {
    return this.buildings[this.currentBuildingId];
};

FarmLocation.prototype.getInfo = function () {
    const buildings = [];
    for (let i = 0; i < this.currentBuildingId; i++) {
        buildings.push(this.buildings[i].save());
    }

    const save = {
        building: this.currentBuildingId,
        quest: this.getBuilding() && this.getBuilding().currentQuestId,
        keys: this.keys,
        buildings
    };

    if (this.justCollectedDialogue) {
        save.justCollectedDialogue = this.justCollectedDialogue;
    }

    return save;
};

FarmLocation.prototype.reset = function () {
    this.buildings.forEach((building) => {
        building.label = undefined;
        building.level = 0;
        building.currentQuestId = 0;
        building.open = false;

        building.quests.forEach((quest) => {
            quest.solved = false;
        });
    });

    this.keys = {};
    this.currentBuildingId = 0;
};

FarmLocation.prototype.listAvailableProvidesBuildings = function () {
    const list = [];
    for (let i = 0; i < this.buildings.length && i < this.currentBuildingId; i++) {
        if (this.buildings[i].provides) {
            list.push(this.buildings[i]);
        }
    }

    if (!list.length && this.currentBuildingId === 0) {
        list.push(this.buildings[0]);
    }

    return list;
};

FarmLocation.prototype.onEnter = function () {
    const building = this.getBuilding();

    if (building && building.isComplete()) {
        building.open = true;
        this.currentBuildingId++;
        this.save();
    }
};

FarmLocation.prototype.moveNext = function (f) {
    const building = this.getBuilding();

    if (this.isCompleted()) {
        f();
        return;
    }

    const items = this.getBuilding().getQuest().items;
    items.forEach(function (key) {
        this.keys[key.name] -= key.amount;
    }, this);

    if (this.getBuilding().nextQuest()) {
        this.trigger("change:stage", f, true);
    } else {
        cleverapps.eventLogger.logEvent(`${cleverapps.EVENTS.METHA_ITEM}_${this.currentBuildingId}`); //
        this.currentBuildingId++;
        const options = {
            finished: building,
            newbie: this.getBuilding()
        };
        this.trigger("change:building", options, f);
    }

    this.save();
};

FarmLocation.prototype.checkForItemDialogue = function (provided) {
    if (this.isCompleted()) {
        return;
    }

    const curQuest = this.getBuilding().getQuest();
    if (curQuest.isCompleted()) {
        return;
    }

    if (cleverapps.config.name === "heroes" && !cleverapps.config.debugMode) {
        return;
    }

    curQuest.getStatus().forEach(function (item) {
        if (item.name === provided) {
            if (item.status < item.amount && (item.status + 1) === item.amount) {
                if (curQuest.itemsDialogue && curQuest.itemsDialogue[item.name]) {
                    this.justCollectedDialogue = curQuest.itemsDialogue[item.name];
                }
            }
        }
    }, this);
};

FarmLocation.prototype.earnKeys = function (key) {
    this.checkForItemDialogue(key);

    this.keys[key] = (this.keys[key] || 0) + 1;

    this.save();
};

FarmLocation.prototype.isCompleted = function () {
    return this.getBuilding() === undefined;
};

FarmLocation.prototype.getLatestBuilding = function () {
    if (this.getBuilding()) {
        return this.getBuilding();
    }

    return this.buildings[this.buildings.length - 1];
};

FarmLocation.prototype.getQuestPrize = function (future) {
    const seed = this.currentBuildingId + this.getBuilding().currentQuestId + (future ? 1 : 0);
    return {
        exp: cleverapps.Random.choose([2, 3, 5], seed),
        unlimitedLives: "30 minutes"
    };
};

FarmLocation.GetMainScene = function () {
    return FarmScene;
};

FarmLocation.GetBuildingProgress = function (targetBuildingId, questNo) {
    const fakeKeys = {};
    const res = {
        keys: {},
        building: targetBuildingId,
        quest: questNo || 0,
        buildings: []
    };

    const farm = cleverapps.meta.getSelectedLocation();
    farm.buildings.forEach((building) => {
        if (building.id > targetBuildingId) {
            return;
        }

        res.buildings[building.id] === undefined && (res.buildings[building.id] = { level: 0 });

        building.quests.forEach((quest, ind) => {
            if (targetBuildingId === building.id && ind >= res.quest) {
                return;
            }

            quest.items.forEach((item) => {
                if (fakeKeys[item.name] === undefined) {
                    fakeKeys[item.name] = 0;
                }

                while (fakeKeys[item.name] < item.amount) {
                    const sourceBuilding = farm.findProperBuilding(item);
                    res.buildings[sourceBuilding.id] === undefined && (res.buildings[sourceBuilding.id] = { level: 0 });
                    res.buildings[sourceBuilding.id].level++;

                    const itemName = sourceBuilding.provides;
                    fakeKeys[itemName] === undefined ? fakeKeys[itemName] = 1 : fakeKeys[itemName]++;
                }

                fakeKeys[item.name] -= item.amount;
            });
        });
    });

    return res;
};
